import React, { FC } from 'react'
import { css } from '@emotion/react'

function isInt(n: number): boolean {
	return Number(n) === n && n % 1 === 0
}

function isFloat(n: number): boolean {
	return Number(n) === n && n % 1 !== 0
}

const niceNumber = (number: number): string => {
	// if(isFloat(number)){
	//     return number.toFixed(2);
	// }
	let value = parseInt(String(number), 10)
	if (Number.isNaN(value)) {
		return '0'
	}

	return value.toLocaleString()
}
const NicePrice: FC<{ number: number }> = ({ number }) => {
	if (number == 0) {
		return (
			<>
				<b>Бесплатно</b>
			</>
		)
	}

	return (
		<>
			<b>{niceNumber(number)} </b>
			<span>р.</span>
		</>
	)
}

const declOfNum = (number: number, titles: string[]): string => {
	let numberDef = number
	if (number % 1 != 0) {
		numberDef = parseInt(number.toString().substr(number.toString().length - 1), 10)
	}

	let cases = [2, 0, 1, 1, 1, 2]
	return titles[
		numberDef % 100 > 4 && numberDef % 100 < 20 ? 2 : cases[numberDef % 10 < 5 ? numberDef % 10 : 5]
	]
}

export { niceNumber, declOfNum, NicePrice }
